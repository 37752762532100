@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Oleo+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Oleo+Script&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.stop-advise {
    font-family: "Noto Sans", sans-serif;
}
.poppins {
    font-family: "Poppins", sans-serif;
}

// .textUnderline {
//     text-decoration: underline;
// }
.bgWhite {
    background-color: #e2e2e2;
}
.bgBlue {
    background-color: #3c8ac8;
}
.pointer {
    cursor: pointer;
}

// check box
.stop-advise-check {
    label {
        color: #000b29;
    }
    .form-check-input {
        margin-top: 0 !important;
        background-color: #fcfcfc !important;
        border-color: #e9eef3 !important;
        width: 19px !important;
        height: 19px !important;
        margin-top: 0em !important;
        &:focus {
            box-shadow: unset !important;
        }

        &:checked[type="radio"] {
            background-image: url("../img/Frame\ 427320538.svg") !important;
        }

        &[type="radio"] {
            background-image: url("../img/Frame\ 427320538\ \(1\).svg") !important;
        }

        &:checked {
            background-color: #fcfcfc !important;
            border-color: #e9eef3 !important;
        }
    }
}
// header
.headerContainer {
    background-color: #3c8ac8;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
        height: 60px;
        padding-left: 10rem;
        @media (max-width: 600px) {
            padding-left: 2rem;
        }
    }
    svg {
        padding-right: 10rem;
    }
}

// footer
.footer {
    padding: 1.1rem 10rem;
    @media (max-width: 600px) {
        text-align: center;
        padding: 0.8rem 2rem;
    }
    span {
        color: #000000;
        text-decoration: underline;
    }
    h6 {
        font-weight: 400;
        font-family: Poppins;
        font-size: 17px;
        @media (max-width: 600px) {
            margin: 0;
            margin-top: 0.3rem;
        }
    }
}
// StopadviesContainer
.StopadviesContainer {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-position-x: right;
}

.HeroImg {
    background-image: url("../img/stopdivise/Rectangle\ 2.png");
}

.bgImg2 {
    background-image: url("../img/stopdivise/bg2.png");
}

.bgImg3 {
    background-image: url("../img/stopdivise/bg3.png");
    background-position-x: center;
}

.bgImg4 {
    background-image: url("../img/stopdivise/bg4.png");
    background-position-x: center;
}
.bgImg5 {
    background-image: url("../img/stopdivise/Rectangle\ 2\ \(2\).png");
    background-position-x: center;
}
.bgImg6 {
    background-image: url("../img/stopdivise/Rectangle\ 2\ \(1\).png");
    background-position-x: center;
}

.FormImage {
    background-image: url("../img/stopdivise/bg5.png");
    background-position-x: center;
}

.ThankYouImage {
    background-image: url("../img/stopdivise/bg6.png");
}
// Herocontainer
.Herocontainer {
    margin: 4rem 10rem;
    margin-bottom: 0.3rem;
    color: #351d45;
    padding-bottom: 2rem;
    @media (max-width: 600px) {
        margin: 4rem 2rem;
        margin-bottom: 0.3rem;
    }
}
.HeroCard {
    border-radius: 10px;
    padding: 2rem 2rem 1rem 2rem;
    @media (max-width: 600px) {
        padding: 3rem 2rem 1rem 2rem;
    }
    h5 {
        color: #ea6c00;
        font-weight: bold;
        // margin-bottom: 0;
        // font-family: Noto Sans;
        font-size: 25px;
    }
    h1 {
        color: #351d45;
        font-weight: bold;
        font-size: 43px;
        b {
            font-size: 40px;
            color: black;
        }
        @media (max-width: 1194.97px) {
            font-size: 37px;
            b {
                font-size: 37px;
            }
        }
        @media (max-width: 1135.97px) {
            font-size: 35px;
            b {
                font-size: 35px;
            }
        }
        @media (max-width: 1096.97px) {
            font-size: 30px;
            b {
                font-size: 30px;
            }
        }
        @media (max-width: 999.97px) {
            font-size: 28px;
            b {
                font-size: 28px;
            }
        }
        @media (max-width: 629.97px) {
            font-size: 1.8rem;
            b {
                font-size: 1.8rem;
            }
        }
        @media (max-width: 378.97px) {
            font-size: 1.3rem;
            b {
                font-size: 1.3rem;
            }
        }
    }
    p {
        padding: 0.5rem 0;
        color: #351d45;
        // font-family: Noto Sans;
        font-size: 16px;
        u {
            color: #351d45;
            // font-family: Noto Sans;
            cursor: pointer;
            font-size: 16px;
            // @media (max-width: 600px) {
            //     font-size: 1.2rem;
            // }
        }
    }
    b {
        font-size: 16px;
    }
    h4 {
        // font-weight: normal;
        font-weight: 700;
        margin-bottom: 0.7rem;
        color: #351d45;
        // font-family: Noto Sans;
        font-size: 22px;
        @media (max-width: 600px) {
            font-size: 1.2rem;
        }
        u {
            color: #351d45;
            // font-family: Noto Sans;
            cursor: pointer;
            font-size: 22px;
            @media (max-width: 600px) {
                font-size: 1.2rem;
            }
        }
    }
    p:last-child {
        color: #676e80;
        font-size: 14px;
        // margin-top: 0.8rem;
        // font-weight: bold;
        // font-family: Noto Sans;
    }
    button {
        font-size: bold;
        background-color: #1ecd47;
        color: white;
        border: none;
        border-radius: 100px;
        padding: 15px 25px 15px 25px;
        font-weight: 500;
        width: fit-content;
        text-align: start;
        // font-family: Noto Sans;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 600px) {
            font-size: 15px;
        }
        .fas {
            font-weight: 600 !important;
            font-size: 10px;
        }
    }
}

.absoluteText {
    background-color: #d77b2b;
    position: absolute;
    right: 30px;
    top: -29px;
    border-radius: 13px;
    padding: 0 1.5rem;
    // @media screen and (min-width: 992px) and (max-width: 1200px) {
    //     left: 52%;
    // }
    // @media screen and (min-width: 600px) and (max-width: 750px) {
    //     left: 47%;
    // }
    // @media screen and (min-width: 375px) and (max-width: 500px) {
    //     left: 46%;
    // }
    // @media screen and (min-width: 500px) and (max-width: 515px) {
    //     left: 50%;
    // }
    // @media screen and (min-width: 300px) and (max-width: 375px) {
    //     left: 35%;
    // }
    p {
        color: white;
        padding: 0;
        margin: 0;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        font-weight: bold;
        text-align: center;
        // font-family: Noto Sans;
    }
}
.bag-text-form {
    top: -28px;
}
// question section
.questionSection {
    h6 {
        color: #ea6c00;
        // font-family: Noto Sans;
        font-weight: bold;
        margin-top: 1.2rem;
        margin-bottom: 0.3rem;
    }
    h2 {
        font-weight: bold;
        font-size: 40px;
        color: #351d45;
        // font-family: Noto Sans;
        @media (max-width: 600px) {
            font-weight: bold;
            font-size: 1.9rem;
        }
    }
    button {
        // background-color: #1ecd47;
        color: white !important;
        border: none;
        border-radius: 100px;
        padding: 8px 25px 8px 25px;
        font-weight: 500;
        width: fit-content;
        text-align: start;
        margin: 1rem 0;
        font-size: 16px;
        // font-family: Noto Sans;
    }
    .back-question-text {
        font-size: smaller !important;
        font-weight: 500 !important;
        text-decoration: underline;
    }
}
.radioInput {
    display: flex;
    justify-content: space-between;
    width: 10vw;
    color: #000b29;
    @media (max-width: 1000px) {
        display: block;
    }
}
.line {
    display: inline-block;
    height: 5px;
    width: 30%;
    margin: 0 5px;
    border-radius: 10px;
    @media (max-width: 1000px) {
        display: inline-block;
        height: 5px;
        width: 28%;
        margin: 0 5px;
        border-radius: 10px;
    }
}
// form section

.formContainer {
    margin: 4rem 0rem;
    margin-bottom: 0.3rem;
    color: #351d45;
    padding-bottom: 2rem;
    display: flex;
    align-items: flex-start;
    @media (max-width: 600px) {
        margin: 4rem 2rem 1rem 2rem;
    }
}

.formSection {
    border-radius: 0px 10px 10px 10px;
    padding: 3rem 4rem;
    border: none;
    outline: none;
    @media (max-width: 991.5px) {
        margin: 1rem 0;
        border-radius: 10px;
    }

    @media (max-width: 600px) {
        padding: 2rem 1.4rem 1rem 1.4rem;
    }
    .arrow {
        position: absolute;
        height: 60px;
        width: 40px;
        top: -30px;
        right: -30px;
        @media (max-width: 600.97px) {
            right: -12px;
        }
    }
    h4 {
        // font-weight: bold;
        // font-family: Noto Sans;
        color: #351d45;
        font-size: 24px;
        padding-bottom: 0.7rem;
        @media (max-width: 1399.5px) {
            font-size: 21px;
        }
        @media (max-width: 1199.5px) {
            font-size: 17px;
        }
        @media (max-width: 991.5px) {
            font-size: 28px;
        }
        @media (max-width: 767.5px) {
            font-size: 27px;
        }
        @media (max-width: 500.97px) {
            padding-right: 16px;
        }
    }
    label {
        font-weight: 700;
        // font-family: Noto Sans;
        color: #292929;
        font-size: 14px;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
    p:last-child {
        font-weight: 400;
        // font-family: Poppins;
        color: #5a7184;
        font-size: 12px;
        margin-left: 5px;
        @media (max-width: 600px) {
            font-size: 11px;
        }
    }
}

.FormBtn {
    button {
        height: 49px;
        background-color: #1ecd47;
        color: white;
        border: none;
        border-radius: 100px;
        padding: 8px 45px 8px 45px;
        width: 100%;
        font-weight: bold;
        // font-family: Noto Sans;
        font-size: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 600px) {
            font-size: 20px;
        }
        .fas {
            font-weight: 600 !important;
            font-size: 10px;
        }
    }
}

.Input-border {
    border-radius: 50px;
    border: 1px solid #e8ecfe;
    background-color: #fafafa;
    padding: 0.7rem 1rem;
    margin: 0.2rem 0;
}
.formIntroCard {
    border-radius: 10px 0px 0px 10px;
    padding: 2.5rem 1rem 1rem 2.4rem;
    margin-right: 0rem;
    border: 0;
    @media (max-width: 600px) {
        padding: 2.5rem 1.4rem 1rem 1.4rem;
        margin-right: 0rem;
    }
    @media (max-width: 991.5px) {
        border-radius: 10px;
    }
    h4 {
        color: #ea6c00;
        // width: 70%;
        padding-right: 40px;
        font-weight: bold;
        font-size: 21px;
        // font-family: Noto Sans;
        @media (max-width: 1199.5px) {
            font-size: 18px;
        }
        @media (max-width: 991.5px) {
            font-size: 21px;
        }
        @media (max-width: 497.5px) {
            padding-right: 0px;
            font-size: 13px;
        }
    }
    h3 {
        color: #351d45;
        font-weight: bold;
        font-size: 21px;
        padding-right: 40px;
        // font-family: Noto Sans;
        @media (max-width: 1399.5px) {
            font-size: 20px;
        }
        // @media (max-width: 1199.5px) {
        //     font-size: 16px;
        // }
        // @media (max-width: 991.5px) {
        //     font-size: 20px;
        // }
        // @media (max-width: 600px) {
        //     font-size: 20px;
        // }
        // @media (max-width: 463.5px) {
        //     font-size: 20px;
        //     padding-right: 0px;
        // }
    }
}
.lastPart {
    padding-top: 1.5rem;
    display: flex;
    img {
        padding-right: 0.6rem;
        @media (max-width: 600px) {
            display: none;
        }
    }
    p {
        color: #351d45;
        padding-top: 0.5rem;
        font-size: 14px;
        // font-family: Noto Sans;
        padding-right: 3rem;
        @media (max-width: 600px) {
            font-size: 12px;
            padding-right: 0rem;
            padding-left: 0.4rem;
        }
    }
}

.cards {
    padding-top: 0.2rem;
    padding-right: 4rem;
    @media (max-width: 600px) {
        padding-top: 0.2rem;
        padding-right: 1rem;
    }
    h5 {
        display: flex;
        align-items: center;
    }
    img {
        height: 16px;
        margin-right: 1.2rem;
    }
    span {
        // font-weight: 700;
        // font-family: Poppins;
        font-size: 13px;
        color: #525252;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
}
// .ThankYou

.Thankyoucontainer {
    margin: 4rem auto;
    margin-bottom: 0.3rem;
    color: #351d45;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    @media (max-width: 600px) {
        margin: 4rem 2rem;
    }
}
.ThankyouCard {
    border-radius: 10px;
    padding: 2rem 2rem 1rem 2rem;
    h3 {
        color: #ea6c00;
        font-size: 24px;
        // font-family: Noto Sans;
        font-weight: bold;
        padding-bottom: 0.4rem;
    }
    h4 {
        color: #351d45;
        font-size: 20px;
        // font-family: Noto Sans;
        font-weight: bold;
    }
}

.errorcontainer {
    margin: 4rem 10rem;
    margin-bottom: 0.3rem;
    color: #351d45;
    padding-bottom: 2rem;
    @media (max-width: 600px) {
        margin: 4rem 2rem;
    }
}
.errorCard {
    border-radius: 10px;
    padding: 2rem 2rem 1rem 2rem;
    h5 {
        color: #351d45;
        font-size: 2rem;
        font-weight: bold;
        padding-bottom: 0.5rem;
        // font-family: Noto Sans;
    }
}

.errorBtn {
    background-color: #1ecd47;
    color: white;
    border: none;
    border-radius: 100px;
    padding: 7px 25px;
    font-weight: 600;
    // font-family: Noto Sans;
    width: fit-content;
    text-align: start;
    margin: 0.5rem 0;
}
.question-u {
    color: #351d45;
    font-family: Noto Sans;
    font-weight: 700;
}
